
/**
 * List of all languages available
 * 
 * @category Maps
 * @summary default language should be first
 */

//If you are adding a locale here, add a country array in netlify>edge-functions>redirectOnce.js
//CMS locale must match the locale in Datp
//label displays in the header locale toggle
//countryEx is any example of a country that would utilize this locale (used to set a country cookie when locales are changed) 
const languageObjectArray = [
  {
    'cmsLocale' : 'en',
    'label' : 'North America',
    'countryEx': 'US'
  },
  {
    'cmsLocale' : 'en-IN',
    'label': 'Asia Pacific',
    'countryEx': 'IN'
  }
  //TODO: add when es is officially added to the site.
  // ,{
  //   'cmsLocale' : 'es',
  //   'label': 'South America',
  //    'countryEx':'MX'
  // }
]



const languageMapArray = languageObjectArray.map((language)=>language.cmsLocale);

const defaultLanguage = languageMapArray[0];

const lowerLanguageArray = languageMapArray.map((language)=>language.toLowerCase());

module.exports = {languageMapArray, languageObjectArray, defaultLanguage, lowerLanguageArray};